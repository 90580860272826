html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, font, img, ins, kbd, q, s, samp, small, strike, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: 'AudiTypeScreen', Verdana,Geneva,sans-serif;
    vertical-align: baseline;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'AudiTypeExtended', Verdana,Geneva,sans-serif !important;
}



body {
    background-color: white;
}

.login-form {

    max-width: 400px;
}

.awlogo {

    height: 34px;
    position: absolute;

    top: 15px;
}

.rings {

    height: 34px;
    position: absolute;
    right: 10px;
    top: 15px;
}

.logos {

    height: 60px;
}

.spinner-container {
    display: flex;
}

.jumbotron {

    min-height: 85vh !important;
}

.download-all {

    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 99;
}

@media screen and (max-width: 550px) {


    .download-all {

        position: fixed;
        right: 20px !important;
        bottom: 20px !important;
    }

    .rings {

        right: 5px !important;
    }
}

.download-all-button {

    background-color: #d0112b;
    height:50px;
    width: 50px;
    padding: 5px;
    border-radius: 25px;
}

.download-no {

    background-color: #d0112b;
    height:50px;
    width: 50px;
    opacity: 0.5;
    padding: 5px;
    border-radius: 25px;
}

.download-icon {

    fill: white;
}
